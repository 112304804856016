<style lang="less" scoped>
@import './index.less'; 
</style>
<template>
  <a-drawer :title="title" width="50%" :closable="false" :visible="visible" @close="onClose(false)" v-if="visible">
    <div>
      <a-tree checkable :tree-data="treeData" :selectedKeys="selectedKeys" :checkedKeys="checkedKeys"
        :replace-fields="replaceFields" @check="checkTree" />
    </div>
    <div :style="{
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9', 
      padding: '10px 16px',
      background: '#ffffff',
      textAlign: 'right',
      zIndex: 1,
    }">
      <a-button style="margin-right: 8px" @click="onClose(false)">关闭</a-button>
      <a-button type="primary" :loading="loading" @click="handleOk">提交</a-button>
    </div>
  </a-drawer>
</template>
<script>
import { reactive, toRefs, ref } from 'vue'
import { toRaw } from 'vue'
import { roleResource, grantResource,resourceTree } from '@/api/system/role'
import { message } from 'ant-design-vue'
export default {
  computed: {
  },
  setup () {
    const state = reactive({
      roleId: null,
      terminalType: null,
      treeData: [],
      visible: false,
      loading: false,
      selectedKeys: ref([]),
      checkedKeys: ref([]),
      replaceFields: {
        children: 'child',
        title: 'name',
        key: 'id'
      }
    })

    const loadData = async (roleId, terminalType) => {
      state.roleId = roleId
      state.loading = true
      state.terminalType = terminalType
      resourceTree({ terminalType: terminalType }).then((res) => {
        if (res.code === 10000) {
          state.treeData = res.data
          roleResource(roleId, { terminalType: terminalType }).then((res) => {
            if (res.code === 10000) {
              state.checkedKeys = res.data
            }
          })
        }
      })

      state.loading = false
    }
    const onClose = (recode) => {
      state.treeData = []
      state.checkedKeys = ref([])
      state.visible = recode
    }
    const checkTree = (selectedKeys, e) => {
      state.checkedKeys = selectedKeys
    }
    const handleOk = () => {
      state.loading = true
      const res = new Map()
      const checkedKeys = toRaw(state.checkedKeys).filter((key) => key !== null && key !== '' && !res.has(key) && res.set(key, 1))
      // 保存当前角色与资源的关系
      grantResource({ roleId: state.roleId, resourceList: checkedKeys,terminalType:state.terminalType }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        state.loading = false
      })
    }

    return {
      loadData,
      ...toRefs(state),
      onclick,
      onClose,
      checkTree,
      handleOk 
    }
  }
}
</script>
